import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

const AddressSummary = props => {

    const { summaryPage } = props

    const { token } = useSelector(state => state.authInfo)

    const [allAddressesFormAxios, setAllAddressesFromAxios] = useState(null)
    const [homeAddresses, setHomeAddresses] = useState([])
    const [officeAddresses, setOfficeAddresses] = useState([])

    useEffect(() => {
        if (token != null) {
            axios.get("/customer/addresses", {
                headers: {
                    Authorization: "Bearer " + token,
                    Accept: 'application/json',
                }
            }).then(response => {
                console.log(response.data)
                setAllAddressesFromAxios(response.data)
            }).catch(errors => {
                console.log(errors.response)
            })
        }
    }, [token])

    useEffect(() => {
        if (allAddressesFormAxios != null) {
            let homeAddresses = allAddressesFormAxios.filter(value => value.address_type_id == 1)
            let officAddresses = allAddressesFormAxios.filter(value => value.address_type_id == 2)
            setHomeAddresses(homeAddresses)
            setOfficeAddresses(officAddresses)
            console.log(homeAddresses)
            console.log(officAddresses)
        }
    }, [allAddressesFormAxios])



    return (
        <div className="address-book">
            {
                summaryPage ? "" : <p className="font-Poppins text-lg mb-4">Default Addresses</p>
            }
            <div className="border-1 border-borderColor px-4 py-4 rounded-lg">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-8">

                    <div>
                        <p className="font-DMSans text-sm font-medium mb-3">Home Addresses</p>

                        {
                            homeAddresses && homeAddresses.map((item, index) => (
                                <div key={index} className="mb-4">
                                    <p className="font-DMSans text-sm1 mb-1">{item.name}</p>
                                    <p className="font-DMSans text-sm1 mb-1">{item.address}</p>
                                    <p className="font-DMSans text-sm1 mb-1">{`${item.area.name}, ${item.city.name}, ${item.region.name}`}</p>
                                    <p className="font-DMSans text-sm1 mb-1">Bangladesh</p>
                                    <p className="font-DMSans text-sm1 mb-3">Mobile: {item.phone}</p>
                                    <p className="font-DMSans text-sm font-medium"><span className="cursor-pointer hover:text-logobarElementBG">Edit Address</span></p>
                                    {
                                        index == homeAddresses.length - 1 ? "" : <hr className="mt-3" />
                                    }
                                </div>
                            ))
                        }
                    </div>

                    <div>
                        <p className="font-DMSans text-sm font-medium mb-3">Office Addresses</p>
                        {
                            officeAddresses && officeAddresses.map((item, index) => (
                                <div key={index} className="mb-4">
                                    <p className="font-DMSans text-sm1 mb-1">{item.name}</p>
                                    <p className="font-DMSans text-sm1 mb-1">{item.address}</p>
                                    <p className="font-DMSans text-sm1 mb-1">{`${item.area.name}, ${item.city.name}, ${item.region.name}`}</p>
                                    <p className="font-DMSans text-sm1 mb-1">Bangladesh</p>
                                    <p className="font-DMSans text-sm1 mb-3">Mobile: {item.phone}</p>
                                    <p className="font-DMSans text-sm font-medium"><span className="cursor-pointer hover:text-logobarElementBG">Edit Address</span></p>
                                    {
                                        index == officeAddresses.length - 1 ? "" : <hr className="mt-3" />
                                    }
                                </div>
                            ))
                        }
                    </div>

                </div>
            </div>
            {
                summaryPage ? "" : (
                    <Link
                        className="inline-block font-Poppins text-sm px-4 py-2 border-1 border-logobarElementBG text-logobarElementBG hover:bg-logobarElementBG hover:text-white rounded-lg mt-8"
                        to="/customer/address-new"
                    >
                        Add New Address
                    </Link>
                )
            }
        </div>
    )
}

export default AddressSummary

