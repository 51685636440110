import React, { useEffect } from 'react'
import { Link, useLocation } from "react-router-dom"

import BillingAddress from '../Components/PageComponents/CheckoutPage/BillingAddress'
import OrderDetailsCheckout from '../Components/PageComponents/CheckoutPage/OrderDetailsCheckout'

const CheckoutPage = () => {

    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <div className="container mx-auto pl-4 pr-4 md:pr-7">
            <div className="container mx-auto mt-6">
                <ol className="list-reset flex text-sm font-medium font-Poppins">
                    <li><Link to="/" className="text-logobarElementBG">Home</Link></li>
                    <li><span className="mx-2 text-mutedText">/</span></li>
                    <li className="text-mutedText">Checkout</li>
                </ol>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-12 gap-8 mt-6">

                <div className="col-span-12 md:col-span-7">
                    <p className="font-Poppins font-semibold text-xl uppercase mb-4 text-center pt-4">billing details</p>
                    <BillingAddress />
                </div>

                <div className="col-span-12 md:col-span-5">
                    <OrderDetailsCheckout />
                </div>

            </div>
        </div>
    )
}

export default CheckoutPage
